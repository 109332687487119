import React from "react";
import clsx from "clsx";
import { Text, Container, Image, Button } from "@atoms";
import LeadPattern from "@svg/LeadPattern";

const ImageCopy = ({ copy, link, image, reverse, className: _className }) => {
  return (
    <section className={clsx("my-10 sm:my-20", _className)}>
      <Container className="relative">
        <div className="md:gap-18 relative z-10 grid items-center gap-8 md:grid-cols-2 md:gap-12 lg:gap-16">
          {/* text container */}
          <div
            className={clsx(
              "relative z-10 flex flex-col items-start justify-center md:py-4",
              {
                "order-2": reverse,
                "order-2 md:order-1": !reverse,
              }
            )}
          >
            <Text variant="h5" className="font-bold text-purple">
              {copy}
            </Text>
            {link?.url && (
              <div className="mt-8">
                <Button to={link.url} color="purple" size="sm">
                  {link.text}
                </Button>
              </div>
            )}
          </div>
          {/* image container */}
          <div
            className={clsx(
              "relative flex h-full p-5 md:max-h-[20rem] lg:max-h-[26rem] xl:max-h-[32rem]",
              {
                "order-1 justify-end": reverse,
                "order-1 justify-start md:order-2": !reverse,
              }
            )}
          >
            <div
              className={clsx(
                "pointer-events-none absolute -bottom-4 h-full w-48 sm:bottom-0 md:w-56 lg:w-64",
                {
                  "-left-24": reverse,
                  "-right-24": !reverse,
                }
              )}
            >
              <LeadPattern className="relative h-full w-full text-teal" />
            </div>
            <div
              className={clsx(
                "relative z-10 h-48 w-full overflow-hidden rounded-b-3xl shadow-xl sm:h-64 md:h-full md:min-h-[16rem]",
                {
                  "rounded-tl-3xl rounded-tr-6xl": reverse,
                  "rounded-tl-6xl rounded-tr-3xl": !reverse,
                }
              )}
            >
              <Image image={image} fill />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

ImageCopy.defaultProps = {
  reverse: false,
};

export default ImageCopy;
