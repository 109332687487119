import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const LeadPattern = ({ className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span className={clsx("icon block", _className)}>
      <m.svg
        width="358"
        height="904"
        viewBox="0 0 358 904"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.rect
          x="1.66071"
          y="557.576"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="145.964"
          y="590.362"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M49.3929 362.97V407.322C49.3929 456.852 89.5452 497.004 139.076 497.004C142.535 497.004 145.339 494.2 145.339 490.741V362.879C145.339 357.633 141.086 353.379 135.839 353.379H58.983C53.6865 353.379 49.3929 357.673 49.3929 362.97Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M10.1437 239.283H54.4958C104.026 239.283 144.179 199.131 144.179 149.6C144.179 146.141 141.374 143.337 137.915 143.337H10.0536C4.80689 143.337 0.553589 147.59 0.553589 152.837V229.693C0.553589 234.99 4.84723 239.283 10.1437 239.283Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M284.125 589.951H202.464C197.218 589.951 192.964 585.698 192.964 580.451V498.79C192.964 495.269 195.818 492.415 199.339 492.415C249.686 492.415 290.5 533.23 290.5 583.576C290.5 587.097 287.646 589.951 284.125 589.951Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M253.093 402.498H126C120.753 402.498 116.5 398.244 116.5 392.998V314.044C116.5 308.772 120.774 304.498 126.047 304.498H167.907C218.492 304.498 259.5 345.505 259.5 396.091C259.5 399.629 256.631 402.498 253.093 402.498Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M351.093 258.5H224C218.753 258.5 214.5 254.247 214.5 249V170.047C214.5 164.774 218.774 160.5 224.047 160.5H265.907C316.492 160.5 357.5 201.508 357.5 252.093C357.5 255.632 354.631 258.5 351.093 258.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M123.339 210.414H205C210.247 210.414 214.5 214.667 214.5 219.914V301.575C214.5 305.095 211.646 307.949 208.125 307.949C157.779 307.949 116.964 267.135 116.964 216.788C116.964 213.268 119.818 210.414 123.339 210.414Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M241.5 776.023V812.983C241.5 862.514 201.348 902.666 151.817 902.666C148.358 902.666 145.554 899.862 145.554 896.402V778.077C145.554 772.83 149.807 768.577 155.054 768.577H234.054C238.166 768.577 241.5 771.911 241.5 776.023Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M49.6072 861.169L49.6072 658.053C49.6072 655.78 51.4502 653.937 53.7237 653.937C104.44 653.937 145.554 695.05 145.554 745.767V861.169C145.554 866.415 141.3 870.669 136.054 870.669H59.1072C53.8605 870.669 49.6072 866.415 49.6072 861.169Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M96.5 134L96.5 4.61905C96.5 2.34416 94.6558 0.5 92.381 0.5C41.6365 0.5 0.499992 41.6365 0.499992 92.381V137.143C0.499992 140.654 3.34618 143.5 6.85714 143.5H87C92.2467 143.5 96.5 139.247 96.5 134Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M58.9291 494.005H103.281C152.812 494.005 192.964 534.157 192.964 583.687C192.964 587.147 190.16 589.951 186.7 589.951H58.839C53.5923 589.951 49.339 585.698 49.339 580.451V503.595C49.339 498.298 53.6326 494.005 58.9291 494.005Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="115.929"
          y="255.861"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          transform="rotate(90 115.929 255.861)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="240.446"
          y="112.5"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          transform="rotate(90 240.446 112.5)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M331.118 782.447H285.183C235.652 782.447 195.5 742.295 195.5 692.764C195.5 689.305 198.304 686.5 201.764 686.5H329.625C334.872 686.5 339.125 690.754 339.125 696V774.44C339.125 778.862 335.54 782.447 331.118 782.447Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

LeadPattern.defaultProps = {};

export default LeadPattern;
